import styled, { css } from 'styled-components';

export type Size = 's' | 'm' | 'l';

const sizeMap: Record<Size, { height: number; width: number }> = {
  s: {
    height: 1.4,
    width: 1.2,
  },
  m: {
    height: 5.1,
    width: 4.4,
  },
  l: {
    height: 9.7,
    width: 8.4,
  },
};

export const Wrapper = styled.div<{ $size: Size }>(
  ({ theme, $size }) => css`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    width: ${sizeMap[$size].width}rem;
    height: ${sizeMap[$size].height}rem;
    color: ${theme.co.primary99};
    font-weight: ${theme.DEPRECATED_ty.bold};
    font-family: ${theme.DEPRECATED_ty.boing};
    font-size: ${$size === 's' ? theme.DEPRECATED_ty.medium : theme.DEPRECATED_ty.display};
  `,
);

export const BadgeContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Level = styled.span`
  position: relative;
`;
