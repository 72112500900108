import React from 'react';
import { CompactUser } from 'src/context/user';
import { defineMessages, IntlShape } from 'react-intl';
import { ROUTES } from 'src/constants/routes.const';
import ProfileDropdownSummary from '../ProfileDropdownSummary';

const msgs = defineMessages({
  profile: {
    id: 'header_dropdown_profile_link_profile',
  },
  settings: {
    id: 'header_dropdown_profile_link_settings',
  },
  learningSettings: {
    id: 'web_ls_menu',
  },
  profileSettings: {
    id: 'web_ls_menu_profile',
    defaultMessage: 'Profile settings',
  },
  logOut: {
    id: 'sign_out',
  },
  learningStats: {
    id: 'learning_stats_classic',
    defaultMessage: 'Learning stats',
  },
  leaderboard: {
    id: 'dashboard_course_card_menu_link_leaderboard',
  },
});

export interface IDropdownSection {
  intl: IntlShape;
  user: {
    loading: boolean;
    error: Error | null;
    user: CompactUser | null;
  };
}

export const getDropdownSections = ({ intl, user }: IDropdownSection) => {
  return [
    {
      dropdownItems: [
        {
          type: 'element',
          element: (
            <ProfileDropdownSummary
              name={user.user?.username ?? 'User'}
              points={/* istanbul ignore next: 0 case */ user.user?.statistics.points ?? 0}
              wordsLearnt={user.user?.statistics.wordsLearnt ?? 0}
            />
          ),
        },
      ],
    },
    {
      dropdownItems: [
        {
          type: 'link',
          linkText: intl.formatMessage(msgs.profile),
          href: `/user/${user.user?.username}/`,
        },
        ...(user.user?.isPro
          ? [
              {
                type: 'link',
                linkText: intl.formatMessage(msgs.learningStats),
                href: '/home/learning-statistics/',
              },
            ]
          : []),
        {
          type: 'link',
          linkText: intl.formatMessage(msgs.leaderboard),
          href: '/home/leaderboard/',
        },
      ],
    },
    {
      dropdownItems: [
        {
          type: 'link',
          linkText: intl.formatMessage(msgs.settings),
          href: ROUTES.settings.user,
        },
        {
          type: 'link',
          linkText: intl.formatMessage(msgs.learningSettings),
          href: ROUTES.settings.learning,
        },
      ],
    },
    {
      dropdownItems: [
        {
          type: 'link',
          linkText: intl.formatMessage(msgs.logOut),
          highlight: true,
          href: ROUTES.signOut,
        },
      ],
    },
  ];
};
