import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: auto minmax(max-content, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas:
      'heading heading'
      'badge   info'
      'stats   stats';
    gap: ${theme.sz.s2};
  `,
);
export const Heading = styled.h3(
  ({ theme }) => css`
    font-weight: ${theme.DEPRECATED_ty.bold};
    margin: 0;
    padding: 0;
    color: ${theme.co.secondary60};
    font-size: ${theme.DEPRECATED_ty.medium};
    grid-area: heading;
  `,
);

export const Badge = styled.div`
  grid-area: badge;
`;

export const Stats = styled.div(
  ({ theme }) => css`
    grid-area: stats;
    font-size: ${theme.DEPRECATED_ty.smaller};
    font-weight: ${theme.DEPRECATED_ty.semiBold};
    color: ${theme.co.secondary50};
  `,
);

export const Info = styled.div(
  ({ theme }) => css`
    grid-area: info;
    font-size: ${theme.DEPRECATED_ty.smaller};
    font-weight: ${theme.DEPRECATED_ty.semiBold};
    color: ${theme.co.secondary50};
  `,
);

export const Progress = styled.div(
  ({ theme }) => css`
    padding-top: ${theme.sz.s1};
  `,
);
