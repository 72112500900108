import styled, { css } from 'styled-components';

export const ErrorContainer = styled.div(
  () => css`
    position: relative;
    top: 1rem;
  `,
);

export const ButtonContainer = styled.div<{ $disabled?: boolean }>(
  ({ $disabled, theme }) => css`
    height: ${theme.sz.s10};
    overflow: hidden;
    position: relative;

    ${$disabled &&
    css`
      filter: grayscale(100%);

      /* 
        Two pseudo-elements necessary to have both 
        [pointer-events: none] and [cursor: not-allowed]
      */
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &::before {
        z-index: ${theme.zi.z1};
        pointer-events: none;
      }

      &::after {
        z-index: ${theme.zi.z2};
        cursor: not-allowed;
      }
    `}
  `,
);

export const LoaderContainer = styled.div(
  ({ theme }) => css`
    max-width: ${theme.sz.s10};
    margin: auto;
  `,
);

export const Error = styled.span<{ $show: boolean }>(
  ({ theme, $show }) => css`
    padding-top: ${theme.sz.s4};
    padding-bottom: 0;
    z-index: -${theme.zi.z1};
    font-weight: ${theme.DEPRECATED_ty.bold};
    font-size: ${theme.DEPRECATED_ty.smaller};
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: ${theme.co.error60};
    color: ${theme.co.neutral99};
    transition:
      transform 300ms cubic-bezier(0, 1, 0.75, 1),
      opacity 300ms cubic-bezier(0, 1, 0.75, 1);
    border-bottom-right-radius: ${theme.sz.s4};
    border-bottom-left-radius: ${theme.sz.s4};
    opacity: ${$show ? '1' : '0'};
    transform: ${$show ? 'none' : 'translateY(-3rem)'};
    display: ${$show ? 'unset' : 'none'};
  `,
);
