import styled, { css, DefaultTheme } from 'styled-components';
import { active, hover } from 'src/components/utils';
import { ClassicButton } from 'src/componentsClassic';

export type GetBackgroundColorTypes = {
  disabled?: boolean;
  theme: DefaultTheme;
};

export const getBackgroundColor = ({ disabled, theme }: GetBackgroundColorTypes) =>
  disabled ? theme.co.secondary80 : theme.co.facebookBlue;

export const FacebookButton = styled(ClassicButton)(
  ({ theme, disabled }) => css<{ disabled?: boolean }>`
    z-index: ${theme.zi.z4};

    && svg {
      width: ${theme.DEPRECATED_ty.largest};
      height: ${theme.DEPRECATED_ty.largest};

      & path {
        fill: ${getBackgroundColor({ disabled, theme })};
      }

      & circle {
        fill: ${theme.co.neutral99};
      }
    }

    && button {
      background-color: ${getBackgroundColor({ disabled, theme })};
      box-shadow: none;
      color: ${theme.co.neutral99};
      border-radius: 100px;
      height: ${theme.sz.s10};
      font-size: ${theme.DEPRECATED_ty.small};
      font-weight: ${theme.DEPRECATED_ty.mediumWeight};

      // Offset in pixels to match Google login button
      padding-left: 55px;

      & span {
        margin: 0;
        position: absolute;
        left: ${theme.sz.s05};
        width: ${theme.DEPRECATED_ty.largest};
        height: ${theme.DEPRECATED_ty.largest};
      }
    }

    &&:hover button {
      background-color: ${hover(getBackgroundColor({ disabled, theme }))};
    }

    &&:active button {
      background-color: ${active(getBackgroundColor({ disabled, theme }))};
    }
  `,
);
