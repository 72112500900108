import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { LevelBadge } from 'src/apps/Classic/LevelBadge';
import { ProgressBar, Stack } from 'src/components';
import * as S from './styles';

const msgs = defineMessages({
  pointsToNext: {
    id: 'header_dropdown_profile_progress_label_rank',
  },
  points: {
    id: 'profile_stats_points',
  },
  wordsLearnt: {
    id: 'profile_stats_words_learnt',
  },
});

const rankRequirements = [
  0, 500, 1000, 2000, 4000, 8000, 16000, 32000, 64000, 128000, 320000, 800000, 2000000, 5000000,
  12500000, 31250000, 78125000, 100000000,
];

const getRankDetails = (points: number) => {
  const currentRank = rankRequirements.findIndex(requirement => requirement > points);

  if (currentRank === -1) {
    return {
      nextRank: rankRequirements.length + 1,
      currentRank: rankRequirements.length + 1,
      pointsToNext: 0,
      progress: 100,
    };
  }

  const nextRank = currentRank + 1;
  const pointsToNext = rankRequirements[currentRank] - points;
  const pointDifference = rankRequirements[currentRank] - rankRequirements[currentRank - 1];
  const progress = ((pointDifference - pointsToNext) / pointDifference) * 100;

  return {
    nextRank,
    currentRank,
    pointsToNext,
    progress,
  };
};

interface Props {
  name: string;
  points: number;
  wordsLearnt: number;
}

const ProfileDropdownSummary: FC<Props> = ({ name, points, wordsLearnt }) => {
  const intl = useIntl();

  const { currentRank, nextRank, pointsToNext, progress } = getRankDetails(points);

  return (
    <S.Wrapper>
      <S.Heading>{name}</S.Heading>
      <S.Badge>
        <LevelBadge level={currentRank} size="s" />
      </S.Badge>
      <S.Info>
        <S.Progress>
          <ProgressBar percentComplete={progress} height="small" />
        </S.Progress>
        <div>
          {intl.formatMessage(msgs.pointsToNext, {
            num_points: intl.formatNumber(pointsToNext),
            level: nextRank,
          })}
        </div>
      </S.Info>
      <S.Stats>
        <Stack $spacing="s1">
          <div>
            <strong>{intl.formatMessage(msgs.points)}</strong>: {intl.formatNumber(points)}
          </div>
          <div>
            <strong>{intl.formatMessage(msgs.wordsLearnt)}</strong>:{' '}
            {intl.formatNumber(wordsLearnt)}
          </div>
        </Stack>
      </S.Stats>
    </S.Wrapper>
  );
};

export default ProfileDropdownSummary;
