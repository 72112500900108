import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useUserContext } from 'src/context/user';
import { convertToMemriseError } from 'src/utils/errors';
import { datadogRum } from '@datadog/browser-rum';
import useSafePush from 'src/hooks/useSafePush';
import * as S from './styles';

export const isUnauthorisedError = (error: Error) => {
  const memriseError = convertToMemriseError(error);
  const unauthorized = 401;
  return memriseError.httpStatusCode === unauthorized;
};

interface Props {
  children?: ReactNode;
  classic?: boolean;
}
const RestrictToAuth: FC<Props> = ({ children, classic }) => {
  const router = useRouter();
  const { safePush } = useSafePush();
  const { error, loading, refetchUser, user } = useUserContext();

  const [checkedTransition, setCheckedTransition] = useState(false);

  const shouldRedirect = !!error && isUnauthorisedError(error);

  useEffect(() => {
    const wasUnauthorizedBeforeTransition = !!error && !loading && !user;
    if (wasUnauthorizedBeforeTransition) refetchUser();
    setCheckedTransition(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!checkedTransition) return;
    if (shouldRedirect) {
      const { asPath } = router;
      const escapedCurrentUrl = encodeURIComponent(
        asPath || /* istanbul ignore next: untested branch of code, please test */ '',
      );

      void safePush(`/signin?next=${escapedCurrentUrl}`);
    } else {
      if (user) {
        datadogRum.setUser({
          id: user.id.toString(),
          isPro: user.isPro,
          isStaff: user.isStaff,
        });
      }
    }
  }, [checkedTransition, router, safePush, shouldRedirect, user]);

  // Log non unauthorized errors
  useEffect(() => {
    if (!checkedTransition) return;
    if (!!error && !isUnauthorisedError(error)) {
      console.error(error.message);
    }
  }, [checkedTransition, error]);

  if (loading || shouldRedirect) {
    return (
      <S.Root>
        <S.Loader />
      </S.Root>
    );
  } else if (error) {
    return (
      <S.Root $classic={classic}>
        <S.ErrorContainer data-testid="auth-error">
          <S.Error key="input-error">
            <S.ErrorTitle>Whoops! Could not connect to Memrise</S.ErrorTitle>
            <S.ErrorDetail>{error.message}</S.ErrorDetail>
          </S.Error>
        </S.ErrorContainer>
      </S.Root>
    );
  } else {
    return <>{children}</>;
  }
};

export default RestrictToAuth;
