import React from 'react';
import * as S from './styles';

interface ProgressBarProps {
  className?: string;
  percentComplete: number;
  color?: 'red' | 'yellow' | 'black' | 'green';
  backgroundColor?: 'dark' | 'light' | 'white';
  linear?: boolean;
  reverse?: boolean;
  height?: S.Height;
}

const ProgressBar = ({
  percentComplete,
  className,
  color = 'yellow',
  backgroundColor = 'light',
  linear,
  reverse,
  height = 'large',
}: ProgressBarProps) => {
  // Ensure value is within 0 - 100
  const safePercentComplete = Math.max(0, Math.min(percentComplete, 100));

  return (
    <S.Background
      className={className}
      data-testid={`progressBar-${Math.round(safePercentComplete)}`}
      $height={height}
      $backgroundColor={backgroundColor}
    >
      <S.MotionedForeground
        height={height}
        percentComplete={safePercentComplete}
        color={color}
        linear={linear}
        reverse={reverse}
      />
    </S.Background>
  );
};

export default ProgressBar;
