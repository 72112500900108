import React, { useEffect, useRef } from 'react';
import getConfig from 'next/config';
import { useIntl } from 'react-intl';
import { GoogleResponse } from 'src/utils/auth';
import { useMediaQuery } from 'react-responsive';
import theme from 'src/utils/theme';
import { useScript } from 'src/hooks';
import { Loader } from 'src/components';
import * as S from './styles';

interface GoogleAuthButtonProps {
  onSignUp: (response: GoogleResponse) => void;
  error?: TranslatedNode;
  disabled?: boolean;
}

interface Google {
  accounts: {
    id: {
      initialize: (input: {
        client_id: string;
        callback: (handleResponse: GoogleResponse) => void;
      }) => void;
      renderButton: (
        parent: HTMLElement,
        options: {
          type: string;
          theme: string;
          size: string;
          shape: string;
          logo_alignment: string;
          width: string;
          text: string;
          locale: string;
        },
      ) => void;
    };
  };
}
declare global {
  interface Window {
    google?: Google;
  }
}

const GoogleAuthButton = ({ onSignUp, error, disabled }: GoogleAuthButtonProps) => {
  const { publicRuntimeConfig } = getConfig();
  const clientId = publicRuntimeConfig.GOOGLE_AUTH_CLIENT_ID;
  const isMobile = useMediaQuery({ maxWidth: theme.sz.lg });
  const intl = useIntl();
  const divRef = useRef<HTMLDivElement>(null);
  const [scriptHasLoaded, hasScriptLoadError] = useScript('https://accounts.google.com/gsi/client');

  /* istanbul ignore next: difficult to cover  */
  useEffect(() => {
    if (!scriptHasLoaded || hasScriptLoadError || !window.google || !divRef.current) {
      return;
    }

    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: onSignUp,
    });
    window.google.accounts.id.renderButton(divRef.current, {
      type: 'standard',
      theme: 'filled_blue',
      size: 'large',
      shape: 'pill',
      logo_alignment: 'left',
      width: isMobile ? '288px' : '400px',
      text: 'signin_with',
      locale: intl.locale,
    });
    // Not adding onSignUp to deps because of infinite rerender problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, hasScriptLoadError, intl.locale, isMobile, scriptHasLoaded]);

  return (
    <div>
      <S.ButtonContainer $disabled={disabled} data-testid="button-container">
        {scriptHasLoaded ? (
          <div ref={divRef} data-testid="google-button" />
        ) : (
          <S.LoaderContainer>
            <Loader />
          </S.LoaderContainer>
        )}
      </S.ButtonContainer>
      <S.ErrorContainer>
        <S.Error data-testid="btn-error" $show={!!error}>
          {error && error}
        </S.Error>
      </S.ErrorContainer>
    </div>
  );
};

export default GoogleAuthButton;
