import styled, { css } from 'styled-components';
import { Loader as LoaderComponent } from 'src/components';

export const Root = styled.div<{ $classic?: boolean }>(
  ({ theme, $classic }) => css`
    align-items: center;
    background-color: ${$classic ? theme.co.primary99 : theme.co.neutral99};
    color: ${theme.co.neutral20};
    display: flex;
    flex-direction: column;
    font-size: ${theme.sz.s3};
    min-height: 100vh;
    height: 100%;
    padding-bottom: ${theme.sz.s8};
    width: 100%;
  `,
);

export const Loader = styled(LoaderComponent)`
  margin: ${props => props.theme.sz.s40} auto 0 auto;
  width: ${props => props.theme.sz.s40};

  @media (max-width: ${props => props.theme.sz.xl}) {
    width: ${props => props.theme.sz.s12};
  }
`;

export const Error = styled.div(
  ({ theme }) => css`
    background-color: ${theme.co.error60};
    border-radius: ${theme.sz.s4};
    color: ${theme.co.neutral99};
    font-size: ${theme.DEPRECATED_ty.medium};
    font-weight: ${theme.DEPRECATED_ty.bold};
    padding: ${theme.sz.s8};
    text-align: center;
  `,
);

export const ErrorTitle = styled.div(
  ({ theme }) => css`
    font-size: ${theme.DEPRECATED_ty.large};
  `,
);

export const ErrorDetail = styled.div(
  ({ theme }) => css`
    overflow: auto;
    padding-top: ${theme.sz.s12};
    text-overflow: ellipsis;
  `,
);

export const ErrorContainer = styled.div(
  ({ theme }) => css`
    margin: ${theme.sz.s40} auto 0 auto;
    max-width: ${theme.sz.xl};

    @media (max-width: ${theme.sz.xl}) {
      width: 80%;
    }
  `,
);
