import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { motion } from 'framer-motion';
import { DEFAULT_COUNTDOWN_TICK_INTERVAL } from 'src/hooks/useCountdownTimer';

export type Height = 'tiny' | 'mediumSmall' | 'small' | 'medium' | 'large';

const getBarShape = (theme: DefaultTheme, height: Height) => css`
  border-radius: ${theme.bo.extraSmall};
  height: ${(() => {
    switch (height) {
      case 'tiny':
        return theme.sz.s1;
      case 'mediumSmall':
        return theme.sz.s3;
      case 'small':
        return theme.sz.s4;
      case 'medium':
        return theme.sz.s5;
      case 'large':
        return theme.sz.s8;
    }
  })()};
  overflow: hidden;
`;

export const Background = styled.div<{
  $height: Height;
  $backgroundColor?: 'dark' | 'light' | 'white';
}>(
  ({ theme, $backgroundColor, $height }) => css`
    background: ${(() => {
      switch ($backgroundColor) {
        case 'dark':
          return theme.co.secondary0;
        case 'light':
          return theme.co.neutral95;
        case 'white':
          return theme.co.neutral99;
      }
    })()};
    ${getBarShape(theme, $height)}
    width: 100%;
  `,
);

interface MotionedForegroundProps {
  percentComplete: number;
  color: string;
  linear?: boolean;
  reverse?: boolean;
  height: Height;
}

const chooseColor = (theme: DefaultTheme, color: string | undefined) => {
  switch (color) {
    case 'black':
      return theme.co.secondary0;
    case 'red':
      return theme.co.error60;
    case 'green':
      return theme.co.success40;
    case 'yellow':
    default:
      return theme.co.primary20;
  }
};

const MotionedForegroundStyle = styled(motion.div)<{ $height: Height }>(({
  theme,
  color,
  $height,
}) => {
  const bgColor = chooseColor(theme, color);
  return css`
    background: ${bgColor};
    ${getBarShape(theme, $height)}
  `;
});

/**
 * Adjusts the width value to work around the fact that when
 * the timer hits zero the animation does not have time to
 * fully animate the progress to the end of the element
 */
const hackPercentToWidth = (pct: number): string => {
  // We cannot divide by zero
  if (pct === 0) return '0%';

  // Otherwise, subtly skew the percentage
  // The goal here is to get the penultimate tick hitting zero
  const skewAmount = 8 / pct;
  // Don't apply skew when at 100% as the user will notice
  // a small gap on the right if it is static
  const oneHundredPctCorrection = 8 / 100;
  return `${pct - skewAmount + oneHundredPctCorrection}%`;
};

export const MotionedForeground = ({
  percentComplete,
  reverse = false,
  linear = false,
  height,
  ...rest
}: MotionedForegroundProps) => (
  <MotionedForegroundStyle
    $height={height}
    animate={{
      width: linear ? hackPercentToWidth(percentComplete) : `${percentComplete}%`,
    }}
    transition={
      linear
        ? {
            type: 'tween',
            ease: 'linear',
            duration: DEFAULT_COUNTDOWN_TICK_INTERVAL / 1000,
          }
        : {
            type: 'spring',
            stiffness: 200,
            mass: 0.5,
          }
    }
    initial={{ width: reverse ? '100%' : '0%' }}
    {...rest}
  />
);
